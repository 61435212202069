//animate.css动画触动一次方法
$.fn.extend({
  animateCss: function (animationName) {
    var animationEnd = "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";
    this.addClass("animated " + animationName).one(animationEnd, function () {
      $(this).removeClass("animated " + animationName);
    });
  },
});

/** ************** Banner ************** */
function initSwiper() {
  const bannerList = window.banner;
  const indexContainer = $(".bd ul");
  const indicatorContainer = $(" #slideBox.slideBox .hd ul");
  const isLoggedIn = $(".before-login.hide").length !== 0;

  bannerList &&
    bannerList !== 0 &&
    bannerList.forEach(function (item, index) {
      const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

      if (imageUrl && redirectGroup !== 1 && (redirectGroup !== 4 || redirectUrl !== "/faq")) {
        /**
         * Redirect Group Option
         * 0 - Promo
         * 1 - Join Us
         * 2 - Lottery
         * 3 - Product
         * 4 - Others inner page (Loyalty, Customer Service)
         * 5 - 自定义
         */

        const indicator = $(`
          <li class="${index === 0 ? "active" : ""}" data-target="#main-slider" data-slide-to="${index}"></li>
        `);

        const banner = $(`
          <li class="swiper-slide" data-swiper-autoplay="5000">
            <a href="#!">
              <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}" />
            </a>
          </li>
        `);

        banner.click(() => {
          if (redirectGroup === 0) {
            $("#modalPromo").addClass("active");
          } else {
            window.initBannerCarouselRedirection({
              redirectUrl,
              redirectGroup,
              redirectPage,
              isLoggedIn,
            });
          }
        });

        /**
         * index append 首页type banner, xycly promo.html no banner
         * 轮播图类型 bannerType
         * 0 - 首页
         * 1 - 优惠活动
         * 2 - 其他
         */
        if (bannerType === 0) {
          indexContainer.append(banner);
          indicatorContainer.append(indicator);
        }
      }
    });
  if (bannerList && bannerList.length) {
    setTimeout(function () {
      $(".slideBox").slide({ mainCell: ".bd ul", effect: "leftLoop", autoPlay: true, mouseOverStop: false });
    }, 0);
  }
}

/** ************** Promo ************** */
$(function () {
  if (window.promo) {
    renderPromo(window.promo);
  } else {
    Object.defineProperty(window, "promo", {
      set: function (data) {
        renderPromo(data);
      },
    });
  }

  function registerPromo(promoId) {
    api.registerPromo(promoId);
  }

  function renderPromo(promoData) {
    if (Array.isArray(promoData)) {
      const promoWrapper = $("#modalPromo");
      const promohash = location.hash !== "" ? location.hash.split("#")[1] : "";
      promoData.forEach(function (value, index) {
        const promoId = value.promoId;
        const requestToJoin = value.requestToJoin;
        const firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

        const promoSidebarItem = $(
          `<div class="modal-sidebar-item ${index === 0 ? "active" : ""}" data-type="${
            value.type
          }" data-id="${promoId}">${value.title}</div>`
        );

        const promoContentItem = $(`<div class="modal-right-content-item ${
          index === 0 ? "active" : ""
        }" data-id="${promoId}">
                                      <div class="content-item-head">
                                        ${value.imagePath ? `<img src="${value.imagePath}" />` : ""}
                                        <h3>${value.title}</h3>
                                      </div>
                                      <div class="content-item-body">
                                        ${value.content}
                                        ${
                                          requestToJoin
                                            ? `
                                         <div class="content-item-footer">
                                         ${
                                           firstDepositPromoJoinMsg
                                             ? `
                                                     <div class="content-item-footer--info">
                                                       ${firstDepositPromoJoinMsg}
                                                     </div>
                                                   `
                                             : `
                                                     <button
                                                       class="content-item-footer--register-promo"
                                                       data-promoid="${promoId}"
                                                     >
                                                       申请参与
                                                     </button>
                                                   `
                                         }
                                         </div>
                                        `
                                            : ""
                                        }
                                      </div>
                                  </div>`);

        promoWrapper.find(".modal-sidebar").append(promoSidebarItem);
        promoWrapper.find(".modal-right-content").append(promoContentItem);
      });
    }
  }

  $(document).on("click", ".modal-sidebar-item", function (e) {
    e.preventDefault();
    const currentId = $(this).data("id");

    $(this).addClass("active").siblings(".modal-sidebar-item").removeClass("active");
    $(`.modal-right-content .modal-right-content-item[data-id='${currentId}']`)
      .addClass("active")
      .siblings(".modal-right-content-item")
      .removeClass("active");
  });

  $(document).on("click", ".content-item-footer--register-promo", function (e) {
    e.preventDefault();
    const promoId = $(this).data("promoid");
    registerPromo(promoId);
  });
});

$(document).ready(function () {
  $("input#loginBtn").click(function (event) {
    event.preventDefault();
  });

  var testCheckout = function (event) {
    event.preventDefault();
    var checker = $("#tccheck");
    if (!checker.is(":checked")) {
      event.stopPropagation();
      alertBox({
        message: "请注明您已阅读并同意条款及条件",
      });
    }
  };
  $("#checkAgreement").click(testCheckout);

  if (Pace) {
    Pace.on("done", initSwiper);
  } else {
    $(window).load(initSwiper);
  }

  /** Open / Close Modal */
  $(".aAgreement").on("click", function () {
    console.log("click");
    $("#modalAgreement").addClass("active");
  });

  $(".aPromo").on("click", function () {
    $("#modalPromo").addClass("active");
  });

  $(".btnCloseModal").on("click", function () {
    $(this).closest(".modal").removeClass("active");
  });
});
